import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/seo"
import Breadcrumb from "../components/Breadcrumb"
import ShareIcons from "../utils/ShareIcons"
import { ArticleMain } from "../styles/Layout"
import {
  StyledQuotes,
  HeroQuoteMeta,
  HeroQuoteText,
  HeroQuoteContent,
  QuoteList,
  HeroQuoteAuthor,
  QuoteItemsContainer,
} from "../components/Quote/QuoteElements"
import SimpleQuoteCard from "../components/Quote/SimpleQuoteCard"
import Newsletter from "../components/Newsletter"

const Quote = ({ data: { site, quote, relatedQuotes } }) => {
  const quoteNodes = relatedQuotes.nodes

  return (
    <>
      <Seo title={quote.title} description={quote.body.body} article={false} />
      <ArticleMain>
        <StyledQuotes>
          <Breadcrumb
            className={null}
            categoryTitle={quote.category?.title}
            categoryLink={quote.category?.slug}
            subCategoryTitle={quote.subCategory?.title}
            subCategoryLink={quote.subCategory?.slug}
          />
          <HeroQuoteContent>
            <HeroQuoteMeta>
              <h2>{quote.title} </h2>
              <time dateTime={new Date(quote.date).toISOString()}>
                {quote.date}
              </time>
            </HeroQuoteMeta>
            <blockquote>
              <HeroQuoteText>
                <span>”</span>
                {quote.body.body}
              </HeroQuoteText>
              <HeroQuoteAuthor>
                <p>
                  {quote.author.name} <span>{`//`} #Ashallayistics</span>
                </p>
                <ShareIcons
                  url={`${site.siteMetadata.siteUrl}/${quote.category.slug}/${quote.subCategory.slug}/${quote.slug}/`}
                />
              </HeroQuoteAuthor>
            </blockquote>
          </HeroQuoteContent>
        </StyledQuotes>

        <QuoteList>
          <QuoteItemsContainer>
            {quoteNodes.map(node => {
              return <SimpleQuoteCard key={node.id} node={node} />
            })}
          </QuoteItemsContainer>
        </QuoteList>
        <Newsletter />
      </ArticleMain>
    </>
  )
}

export default Quote

export const quoteQuery = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }

    quote: contentfulQuote(slug: { eq: $slug }) {
      title
      slug
      date(formatString: "MMM D, YYYY")
      category {
        title
        slug
      }
      subCategory {
        title
        slug
      }
      body {
        body
      }
      author {
        name
      }
    }

    relatedQuotes: allContentfulQuote(
      sort: { order: DESC, fields: date }
      limit: 6
      filter: { slug: { nin: [$slug, null] } }
    ) {
      nodes {
        id
        title
        slug
        date(formatString: "MMM D, YYYY")
        body {
          body
        }
        category {
          slug
        }
        subCategory {
          slug
          title
        }
        author {
          name
        }
      }
    }
  }
`
