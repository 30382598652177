import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { BreadcrumbItem, BreadcrumbStyles } from "./BreadcrumbElements"

const Breadcrumb = ({
  className,
  categoryTitle,
  categoryLink,
  subCategoryTitle,
  subCategoryLink,
}) => (
  <BreadcrumbStyles className={className} aria-label="Breadcrumb">
    <ul>
      <BreadcrumbItem>
        <Link to="/">Home</Link>
      </BreadcrumbItem>

      <BreadcrumbItem>
        <Link to={`/${categoryLink}`}>{categoryTitle}</Link>
      </BreadcrumbItem>
      {subCategoryLink && (
        <BreadcrumbItem>
          <Link to={`/${categoryLink}${subCategoryLink}`}>
            {subCategoryTitle}
          </Link>
        </BreadcrumbItem>
      )}
    </ul>
  </BreadcrumbStyles>
)

Breadcrumb.propTypes = {
  categoryTitle: PropTypes.string.isRequired,
  subCategoryTitle: PropTypes.string,
  categoryLink: PropTypes.string.isRequired,
  subCategoryLink: PropTypes.string,
}

export default Breadcrumb
