import React from "react"
// import { Link } from "gatsby"

import {
  SingleQuoteCard,
  QuoteCardLink,
  QuoteCardAuthor,
} from "./QuoteElements"

const SimpleQuoteCard = ({ node }) => {
  return (
    <SingleQuoteCard>
      <QuoteCardLink
        to={`/${node.category.slug}/${node.subCategory.slug}/${node.slug}`}
      >
        <span>”</span>
        <h2>{node.body.body}</h2>
        <QuoteCardAuthor>
          <h3>{node.title}</h3>
          <p>
            {node.author.name} // {node.date}
          </p>
        </QuoteCardAuthor>
      </QuoteCardLink>
    </SingleQuoteCard>
  )
}

export default SimpleQuoteCard
