import styled from "@emotion/styled"

export const BreadcrumbStyles = styled.nav`
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 1.18rem;
  grid-column: 2 / -2;
  grid-row: 1 / 2;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  padding-top: 3rem;
  padding-bottom: 0.5rem;
  z-index: 20;

  ul {
    display: flex;
  }
`

export const BreadcrumbItem = styled.li`
  &:last-of-type {
    opacity: 0.72;
  }

  &:not(:first-of-type)::before {
    /* content: "/"; */
    content: "→";
    color: var(--grey-7);
  }

  a {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    color: currentColor;

    &:hover {
      opacity: 0.55;
    }
  }
`
